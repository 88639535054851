<template>
  <div style="background: #fff; padding: 15px;height: calc(100vh - 120px);display: flex;flex-direction: column;">
    <div v-if="searchColumns.length" class="search-bar" style="margin-bottom: 10px;">
      <span v-for="o in searchColumns" :key="o.prop" style="display:inline-block; margin-right: 10px; margin-bottom: 5px;">

        <el-date-picker v-if="o.valueType === 'datetime'" v-model="search[o.prop]" type="datetimerange" :start-placeholder="`${o.label}开始`" :end-placeholder="`${o.label}结束`" value-format="yyyy-MM-dd HH:mm:ss" />
        <el-select v-else-if="o.valueMap" v-model="search[o.prop]" :placeholder="o.label" clearable>
          <el-option v-for="(label, value) in o.valueMap" :key="value" :value="value" :label="label" />
        </el-select>
        <el-input v-else v-model="search[o.prop]" :placeholder="o.label" style="width: 200px;" clearable :readonly="o.readonly" />
      </span>
    </div>

    <div class="action-bar" style="margin-bottom: 15px;">
      <el-button type="primary" icon="el-icon-plus" size="mini" @click="showFeeEditForm"><i class="icon-add" />新增</el-button>
      <!-- <el-upload style="display: inline-block;"
        action="/api/upload"
        :show-file-list="false"
        :on-change="createObject"
        :on-error="e => $message.error(e.message)"
        >
        <el-button slot="trigger" type="primary" icon="el-icon-upload" size="mini">上传</el-button>
      </el-upload> -->

      <!-- <el-button :disabled="loading || selection.length === 0" type="danger" icon="el-icon-delete" size="mini" @click="removeRecord(selection)">删除{{ selection.length ? '选择' : '' }}</el-button> -->

      <!-- <el-button :disabled="loading || total === 0" type="warning" icon="el-icon-download" size="mini" @click="downloadRecord">导出{{ selection.length ? '选择' : '' }}</el-button> -->

      <el-popover>
        <el-button slot="reference" type="warning" icon="el-icon-setting" size="mini">配置</el-button>
        <div style="min-width: 300px;">
          <el-tabs value="first">
            <el-tab-pane label="字段设置" name="first">
              <el-table :data="columns" class="setting-table">
                <el-table-column prop="label" label="字段" />
                <el-table-column prop="hide" label="显示" width="50">
                  <template slot-scope="{row}">
                    <el-checkbox :value="!getTableConfig(`column.${row.prop}.hide`, row.hide)" @change="setTableConfig(`column.${row.prop}.hide`, !$event)" />
                  </template>
                </el-table-column>
                <el-table-column prop="search" label="查询" width="50">
                  <template slot-scope="{row}">
                    <el-checkbox v-if="row.searchable" :value="getTableConfig(`column.${row.prop}.search`, row.search)" @change="setTableConfig(`column.${row.prop}.search`, $event)" />
                  </template>
                </el-table-column>
                <el-table-column prop="download" label="导出" width="50">
                  <template slot-scope="{row}">
                    <el-checkbox :value="getTableConfig(`column.${row.prop}.download`, row.download)" @change="setTableConfig(`column.${row.prop}.download`, $event)" />
                  </template>
                </el-table-column>
                <el-table-column prop="upload" label="导入" width="50">
                  <template slot-scope="{row}">
                    <el-checkbox v-if="row.uploadable" :value="getTableConfig(`column.${row.prop}.upload`, row.upload)" @change="setTableConfig(`column.${row.prop}.upload`, $event)" />
                  </template>
                </el-table-column>
              </el-table>
            </el-tab-pane>
            <el-tab-pane label="表格设置" name="second">
              <div style="margin-top: 10px;">
                <el-checkbox style="display: block;" :value="getTableConfig('table.border', false)" @change="setTableConfig('table.border', $event)">表格边框（打开边框可设置列宽度）</el-checkbox>
                <el-checkbox style="display: block;" :value="getTableConfig('table.campact', false)" @change="setTableConfig('table.campact', $event)">紧凑模式</el-checkbox>
              </div>
            </el-tab-pane>
          </el-tabs>

        </div>
      </el-popover>
    </div>

    <div style="flex:1">
      <el-table
        v-loading="loading"
        :data="tableData"
        height="100%"
        :border="getTableConfig('table.border', false)"
        :default-sort="sort"
        :class="getTableConfig('table.campact', false) ? 'table-campact' : ''"
        @selection-change="selectionChange"
        @header-dragend="handerDragend"
        @sort-change="sortChange"
        @cell-dblclick="cellDblclick"
      >
        <el-table-column type="selection" width="55" />
        <el-table-column
          v-for="o in tableColumns"
          :key="o.prop"
          :prop="o.prop"
          :label="o.label"
          :width="o.width ? getTableConfig(`column.${o.prop}.width`, o.width) : undefined"
          :min-width="o.minWidth ? getTableConfig(`column.${o.prop}.width`, o.width) : undefined"
          show-overflow-tooltip
          :sortable="o.sortable ? 'custom' : undefined"
          :sort-orders="o.sortable ? ['descending', 'ascending', null] : undefined"
        >
          <template slot-scope="{row}">
            <component :is="o.component" v-if="o.component" :options="o.componentOptions(row)">{{ renderCell(o, row) }}</component>
            <template v-else>{{ renderCell(o, row) }}</template>
          </template>
        </el-table-column>

        <!-- <el-table-column label="操作" width="400" :resizable="false">
          <template slot-scope="{row}">
            <el-button type="success" icon="el-icon-edit" size="mini" @click="$router.push(`/project/my/${row.id}`)">查看</el-button>
            <el-button type="success" icon="el-icon-edit" size="mini" @click="updateRecord(row)">编辑</el-button>
            <el-button type="danger" icon="el-icon-delete" size="mini" @click="removeRecord(row)">删除</el-button>
            <el-button type="primary" icon="el-icon-check" size="mini" @click="updateRecord_status_2(row)">提交</el-button>
          </template>
        </el-table-column> -->
      </el-table>
    </div>
    <el-pagination
      style="margin-top: 20px; text-align: right;"
      background
      :current-page.sync="currentPage"
      :page-size.sync="pageSize"
      :page-sizes="[20,50,100,500,1000]"
      layout="total, ->, prev, pager, next, sizes"
      :total="total">
    </el-pagination>

    <!-- <el-table :data="tableData1" style="width: 100%">
      <el-table-column prop="name" label="资料名称" width="180"> </el-table-column>
      <el-table-column prop="url" label="下载/查看" width="180"> </el-table-column>
      <el-table-column prop="create_user_name" label="创建人" width="180"> </el-table-column>
      <el-table-column prop="create_time" label="创建时间" width="180"> </el-table-column>
    </el-table> -->

    <fee-edit-form ref="feeEditForm" />
  </div>
</template>
<script>
import _ from 'lodash';
import moment from 'moment'
import * as XLSX from 'xlsx';
import { mapState } from 'vuex';

import FeeEditForm from './form/FeeEditForm';

export default {
  components: { FeeEditForm },
  props: {
    info: {
      type: Object,
      default: () => null,
    }
  },
  data() {
    return {
      tableData: [],
      currentPage: 1,
      pageSize: 20,
      total: 0,
      loading: false,
      sort: { prop: 'create_time', order: 'descending' },
      selection: [],
      search: { fee_calc_type: [ 1, 2, 3, 4 ] },

      tableConfig: {},
      downloadState: {
        progressing: false
      },

      zjjllx_list: [],

      fee_calc_type_options: [
        { label: '加总额', value: 1 },
        { label: '减总额', value: 2 },
        { label: '加使用', value: 3 },
        { label: '减使用', value: 4 },
      ],
    }
  },
  computed: {
    ...mapState(['accountInfo']),
    columns() {
      return [
        { prop: 'id', label: 'ID', width: 100, hide: true, sortable: true },
        { prop: 'type_name', label: '记录类型', width: 80 },
        { prop: 'fee', label: '金额（万元）', width: 100 },
        { prop: 'fee_calc_type_name', label: '计算类型', width: 100 },
        { prop: 'name', label: '备注', width: 300 },

        { prop: 'create_user_name', label: '操作人', width: 100, sortable: true },
        { prop: 'create_time', label: '操作时间', width: 160, sortable: true },
      ]
    },
    tableColumns() {
      return _.reject(this.columns, o => this.getTableConfig(`column.${o.prop}.hide`, o.hide));
    },

    searchColumns() {
      return _.filter(this.columns, o => o.searchable && this.getTableConfig(`column.${o.prop}.search`, o.search));
    },

    hasUploadColumns() {
      return !!_.find(this.columns, o => (o.uploadable && this.getTableConfig(`column.${o.prop}.upload`, o.upload)));
    }
  },
  watch: {
    currentPage() {
      this.refresh()
    },
    pageSize(pageSize) {
      this.setTableConfig('pageSize', pageSize);
      this.refresh()
    },
    sort() {
      this.refresh()
    },
    search: {
      handler() {
        this.refresh()
      },
      deep: true,
    }
  },
  created() {
    
    this.$api.executeArray(
      ['sys_params.list', { code: `tableConfig_${this.$route.path}` }],
      ['sys_dict.list', { code: 'zjjllx' }]
    ).then(([sys_params_list_res, sys_dict_list_res]) => {


      this.zjjllx_list = _.find(sys_dict_list_res.list, { code: 'zjjllx'})?.children;
      console.log(this.zjjllx_list)

      this.tableConfig = JSON.parse((sys_params_list_res.list[0] ? sys_params_list_res.list[0].value : null) || '{}')
      this.pageSize = this.tableConfig.pageSize || this.pageSize || 10
      this.refresh()
    })
  }, 
  methods: {
    setTableConfig(path, value) {
      this.tableConfig = _.set(_.clone(this.tableConfig), path, value)
      this.$api.execute('sys_params.replace', { code: `tableConfig_${this.$route.path}`, value: JSON.stringify(this.tableConfig) })
    },
    getTableConfig(path, def) {
      const value = _.get(this.tableConfig, path);
      if(value === undefined) return def;
      return value;
    },
    getQuery(page) {
      return _.assign({
        cash_pool_id: this.info.id,
        type: this.type,
        // user_id: this.accountInfo.user.id,
      },
        _.pickBy(this.search, o => o !== '' && o !== null),
        _.pick(this, ['sort']),
        page ? _.pick(this, ['currentPage', 'pageSize']) : {}
      )
    },
    refresh() {
      this.loading = true;
      this.refresh_impl();
    },
    refresh_impl: _.debounce(async function() {
      try {
        this.loading = true;
        const res = await this.$api.execute('ycjh_cms.cash_pool.log.list', this.getQuery(true));
        this.tableData = _.map(res.list, o => _.assign({}, o, {
          type_name: _.find(this.zjjllx_list, { code: o.type })?.name || o.type,

          fee_calc_type_name: _.find(this.fee_calc_type_options, { value: o.fee_calc_type })?.label,
        }, o.project_id ? {
          name: `项目【${o.project_name}】`
        } : {}));
        this.total = res.count;
        this.loading = false;
      } catch(e) {
        this.loading = false;
        this.$this.$notify({
          type: 'error',
          message: e.message
        });
      }
    }, 300),

    renderCell(column, row) {
      if (column.render) return column.render(row)

      const value = row[column.prop]
      if (column.valueMap) {
        const valueMap = _.isFunction(column.valueMap) ? column.valueMap() : column.valueMap
        return valueMap[value]
      }

      if (column.valueType === 'date') return value ? moment(value).format('YYYY-MM-DD') : ''
      if (column.valueType === 'datetime') return value ? moment(value).format('YYYY-MM-DD HH:mm:ss') : ''

      return value
    },

    handerDragend(newWidth, oldWidth, column, event) {
      this.setTableConfig(`column.${column.property}.width`, newWidth);
    },

    sortChange({ prop, order }) {
      this.sort = order ? { prop, order } : null;
    },
    selectionChange(selection) {
      this.selection = selection
    },

    createRecord() {
      this.$refs.editForm.show({ status: 1 }, { title: '项目申请', success: () => {
        this.refresh();
        this.$emit('count-change');
      } });
    },
    updateRecord(obj) {
      this.$refs.editForm.show(obj, { title: '编辑申请', success: () => {
        this.refresh();
      } });
    },

    async updateRecord_status_2(obj) { // 提交
      try {
        const [arr, msg] = _.isArray(obj)
          ? [[...obj], `此操作将提交选择的${obj.length}条记录, 是否继续?`]
          : [[obj], '此操作将提交该记录, 是否继续?']

        await this.$confirm(msg, '提示', { confirmButtonText: '确定', cancelButtonText: '取消', type: 'warning' })
        await this.$api.executeArray(..._.map(arr, o => ([
          'ycjh_cms.project.update', o.id, { status: 2 }
        ])))

        this.refresh()
        this.$emit('count-change');
        this.$message({ type: 'success', message: '操作成功!' })
      } catch (e) {
        if (!_.includes(['cancel', 'close'], e)) {
          this.$notify({
            type: 'error',
            message: e.message
          });
        }
      }
    },

    async removeRecord(obj) {
      try {
        const [arr, msg] = _.isArray(obj)
          ? [[...obj], `此操作将永久删除选择的${obj.length}条记录, 是否继续?`]
          : [[obj], '此操作将永久删除该记录, 是否继续?']

        await this.$confirm(msg, '提示', { confirmButtonText: '确定', cancelButtonText: '取消', type: 'warning' })
        await this.$api.executeArray(..._.map(arr, o => ([
          'ycjh_cms.project.remove', o.id
        ])))

        this.refresh()
        this.$emit('count-change');
        this.$message({ type: 'success', message: '删除成功!' })
      } catch (e) {
        if (!_.includes(['cancel', 'close'], e)) {
          this.$notify({
            type: 'error',
            message: e.message
          });
        }
      }
    },

    async downloadRecord() {
      const list = this.selection.length ? this.selection : (await this.$api.execute('ycjh_cms.project.list', this.getQuery())).list
      const wb = XLSX.utils.book_new()
      const columns = _.filter(this.columns, o => this.getTableConfig(`column.${o.prop}.download`, o.download))
      if (!_.size(columns)) {
        this.$message.error('请选择导出的列')
        return
      }
      const ws = XLSX.utils.aoa_to_sheet([
        _.map(columns, 'label'),
        ..._.map(list, o => _.map(columns, p => this.renderCell(p, o)))
      ])

      var range = XLSX.utils.decode_range(ws['!ref'])
      ws['!cols'] = _.map(_.range(range.e.c - range.s.c + 1), c => {
        const wch = _(_.range(range.e.r - range.s.r + 1)).map(r => {
          var cell_ref = XLSX.utils.encode_cell({ c, r })
          return Math.max((ws[cell_ref] ? _(ws[cell_ref].v).map(str => str.match(/[\u4E00-\u9FFF]/) ? 2 : 1).sum() : 0) + 4, 10)
        }).max()
        return { wch }
      })

      XLSX.utils.book_append_sheet(wb, ws, 'Sheet1')
      XLSX.writeFile(wb, `导出${moment().format('YYMMDD')}共${list.length}条.xlsx`, {})
    },
    async createObject(e) {

      console.log('createObject', e, e.status, this.info);
      if(e.status === 'success') {
        const url = _.get(e, 'response[0].url');
        const name = _.last(url.split('/'))
        const res = await this.$api.execute('ycjh_cms.project.log.create', {
          project_id: this.info.id,
          type: '设计阶段',
          name,
          payload: JSON.stringify({ url })
        });
      }
    },

    showFeeEditForm() {
      this.$refs.feeEditForm.show({
        cash_pool_id: this.info.id,
      }, {
        title: '新增资金记录',
        success: () => {
          this.refresh();
          this.$emit('info-change')
        }
      });
    }
  }
}
</script>